import { Controller } from "stimulus"
export default class extends Controller {
  static values = { excluded: Array }
  checkState(e) {
    const excludedStates = this.data.get('excluded-value')
    if (excludedStates.includes(e.target.value)) {
      alert(`I'm sorry, we do not ship to ${e.target.value} at this time. Please select a different state`)
      e.target.value = ''
    }
  }
}
