require('es6-promise')
require('es6-promise/auto')
require('whatwg-fetch')
const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "filename", "hiddenInput", "trigger", "preview" ]

  initialize() {
  	const uppy = new Uppy({
  		autoProceed: true, 
  		allowMultipleUploads: false
  	})
    uppy.use(Dashboard, {
      trigger: this.triggerTarget,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
    })
    .use(XHRUpload, {
      endpoint: '/upload',
    })
    uppy.on('upload-success', (file, response) => {
      const uploadedFileData = response.body
      this.hiddenInputTarget.value = JSON.stringify(uploadedFileData)
      this.filenameTarget.innerHTML = `${file.name}`
      this.updatePreviewSection()
    })
    uppy.on('thumbnail:generated', (file, preview) => {
      const img = document.createElement('img')
      img.src = preview
      img.width = 100
      this.previewTarget.innerHTML = ""
      this.previewTarget.appendChild(img)
    })
  }

  updatePreviewSection() {
    const previewUpload = document.getElementById('shrine-upload-image')
    if ( previewUpload ) {
      previewUpload.remove()
    }
    document.getElementById('preview-img').classList.add('p-t-lg')
    document.getElementById('preview-img-filename').classList.add('p-t')
  }
}
