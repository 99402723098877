import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['form']
  validateZip() {
    const zipCode = event.target.value.trim()
    const formGroup = event.target.closest('.form-group')
    const errorMsg = formGroup.querySelector('.help-block')
    if (/^\d{5}(-\d{4})?$/.test(zipCode)) {
      if (formGroup.classList.contains('has-error')) {formGroup.classList.remove('has-error')
        errorMsg.remove()
      }
    } else {
      formGroup.classList.add('has-error')
      if (!errorMsg) {
        formGroup.append(this.createErrorSpan())
      }
    }
  }

  createErrorSpan() {
    const errorElement = document.createElement('span')
    errorElement.classList.add('help-block')
    errorElement.innerHTML = "The zip code format is invalid. Please enter a valid 5-digit or 9-digit zip code (e.g., 12345 or 12345-6789)."
    return errorElement
  }

  validateShipToForm(e) {
    const error = document.querySelector('.has-error')
    if (error) {
      remoteFlash('Please fix errors before proceeding', 'alert')
      e.preventDefault()
      return
    } 
    this.validateZipState(e).then((data, form) => {
      if (!data.valid) {
        remoteFlash('Invalid zipcode for state.', 'alert')
        e.preventDefault()
        return
      }
      e.target.closest('form').submit()
    })
    .catch(error => {
      console.error('Fetch error:', error.message)
    })
  }

  getValueById(id) {
    return document.getElementById(id).value
  }

  get getCsrToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  async validateZipState(e) {
    const zip = this.getValueById('order_zip')
    const state = this.getValueById('order_state')
    const city = this.getValueById('order_city')
    const url = `/checkout/validate_zip_state?zip=${zip}&state=${state}&city=${city}`
    const fetchOption = {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest', // To mimic Rails UJS
        'Accept': 'text/javascript',
        'Content-Type': 'text/javascript',
        'X-CSRF-Token': this.getCsrToken
      }
    }
    const response = await fetch(url, fetchOption)
    if (!response.ok) {
      throw new Error(`Unexpected HTTP error! status: ${response.status}`)
    }

    const validZipResponse = await response.json()
    return validZipResponse
  }
}