import { Controller } from "stimulus"
export default class extends Controller {
  //static values = { excluded: Array }
  validateData(e) {
    if (
      this.checkFirstName() +
      this.checkLastName() +
      this.checkStreetAddress() +
      this.checkCity() +
      this.checkState() +
      this.checkZip() +
      this.checkPhone() == 0
    ) {
      create_doctor_button()
    } else {
      var correctionsRequired = document.getElementsByClassName('has-error')
      correctionsRequired[0].querySelector('input').focus()
    }
  }
  checkFirstName() {
    var firstNameInput = document.getElementById('doctor_first_name')
    var doctorFirstNameRow = document.getElementById('doctor_first_name_row')
    var firstNameErrorSpan = document.getElementById('first_name_error')
    if (firstNameInput.value == "") {
      setError(doctorFirstNameRow,firstNameInput,'doctor#checkFirstName',firstNameErrorSpan,)
      return 1
    }else{
      clearError(doctorFirstNameRow,firstNameInput,firstNameErrorSpan,)
      return 0
    }
  }
  checkLastName() {
    var lastNameInput = document.getElementById('doctor_last_name')
    var doctorLastNameRow = document.getElementById('doctor_last_name_row')
    var lastNameErrorSpan = document.getElementById('last_name_error')
    if (lastNameInput.value == "") {
      setError(doctorLastNameRow,lastNameInput,'doctor#checkLastName',lastNameErrorSpan,)
      return 1
    }else{
      clearError(doctorLastNameRow,lastNameInput,lastNameErrorSpan,)
      return 0
    }
  }
  checkStreetAddress() {
    var streetAddressInput = document.getElementById('practice_street')
    var streetAddressRow = document.getElementById('practice_street_row')
    var streetAddressErrorSpan = document.getElementById('practice_street_error')
    if (streetAddressInput.value == "") {
      setError(streetAddressRow,streetAddressInput,'doctor#checkStreetAddress',streetAddressErrorSpan,)
      return 1
    }else{
      clearError(streetAddressRow,streetAddressInput,streetAddressErrorSpan,)
      return 0
    }
  }
  checkCity() {
    var cityInput = document.getElementById('practice_city')
    var practiceCityRow = document.getElementById('practice_city_row')
    var cityErrorSpan = document.getElementById('city_error')
    if (cityInput.value == "") {
      setError(practiceCityRow,cityInput,'doctor#checkCity',cityErrorSpan,)
      return 1
    }else{
      clearError(practiceCityRow,cityInput,cityErrorSpan,)
      return 0
    }
  }
  checkState() {
    var stateSelect = document.getElementById('practice_state')
    var practiceStateRow = document.getElementById('practice_state_row')
    var stateErrorSpan = document.getElementById('state_error')
    if (stateSelect.value == "") {
      setError(practiceStateRow,stateSelect,'doctor#checkState',stateErrorSpan,)
      return 1
    }else{
      clearError(practiceStateRow,stateSelect,stateErrorSpan,)
      return 0
    }
  }
  checkZip() {
    var zipInput = document.getElementById('practice_zip')
    var practiceZipRow = document.getElementById('practice_zip_row')
    var zipErrorSpan = document.getElementById('zip_error')
    if (zipInput.value == "") {
      setError(practiceZipRow,zipInput,'doctor#checkZip',zipErrorSpan,)
      return 1
    }else{
      clearError(practiceZipRow,zipInput,zipErrorSpan,)
      return 0
    }
  }
  checkPhone() {
    var phoneInput = document.getElementById('practice_phone')
    var practicePhoneRow = document.getElementById('practice_phone_row')
    var phoneErrorSpan = document.getElementById('phone_error')
    if (phoneInput.value == "") {
      setError(practicePhoneRow,phoneInput,'doctor#checkPhone',phoneErrorSpan,)
      return 1
    }else{
      clearError(practicePhoneRow,phoneInput,phoneErrorSpan,)
      return 0
    }
  }
}

function setError(row, input, check_method_name, error_label, ) {
  row.classList.add('has-error')
  input.setAttribute('data-controller','doctor')
  input.setAttribute('data-action',check_method_name)
  error_label.classList.remove('hidden')
}
function clearError(row, input, error_label) {
  row.classList.remove('has-error')
  input.removeAttribute('data-action')
  error_label.classList.add('hidden')
}

function setAttributes(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}
